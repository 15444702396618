<template>
  <v-data-table
    v-model="selected"
    class="data-table"
    :headers="headers"
    :items="rentsList"
    :single-expand="singleExpand"
    :expanded.sync="expanded"
    :search="search"
    show-select
    show-expand
  >
    <template v-slot:top>
      <v-toolbar
        elevation="0"
        flat
      >
        <v-row align="center">
          <v-col
            cols="12"
            sm="6"
            lg="4"
          >
            <v-text-field
              v-model="search"
              label="Поиск"
              outlined
              dense
              append-icon="mdi-magnify"
              hide-details="true"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-toolbar>
    </template>

    <template v-slot:item.photo="{ item }">
      <div
        class="p-2"
        v-if="notEmpty(item.photo)"
      >
        <div class="photo-slider">
          <vue-slick-carousel v-bind="slickOptions">
            <div
              class="photo-slider__item"
              v-for="(photo, index) in item.photo"
              :key="index"
            >
              <a
                :href="photo.src"
                :data-fancybox="'rent-gallery-' + item.id"
              >
                <img
                  :src="photo.src"
                  :alt="item.auto"
                />
              </a>
            </div>
          </vue-slick-carousel>
        </div>
      </div>
    </template>

    <template v-slot:item.start="{ item }">
      {{ $moment(item.start).format("DD.MM.YYYY") }} -
      {{ $moment(item.finish).format("DD.MM.YYYY") }}
    </template>

    <template v-slot:item.car_brand="{ item }">
      {{ getCarByGuid(item.car_brand)?.name }}
    </template>

    <template v-slot:item.driver_id_1="{ item }">
      {{ getFullName(item.driver_id_1) }}
    </template>

    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length">
        <v-row class="m-4">
          <v-col
            col="12"
            sm="6"
            lg="3"
          >
            <div class="more-info">Дата старта:</div>
            {{ item.start }}
          </v-col>
          <v-col
            col="12"
            sm="6"
            lg="3"
          >
            <div class="more-info">Дата окончания:</div>
            {{ item.finish }}
          </v-col>
          <v-col
            col="12"
            sm="6"
            lg="3"
          >
            <div class="more-info">Сумма за день:</div>
            {{ item.price_day }} ₽
          </v-col>
          <v-col
            col="12"
            sm="6"
            lg="3"
          >
            Водители:
            <div v-if="item.driver_id_1">
              {{ getFullName(item.driver_id_1) }}
            </div>
            <div v-if="item.driver_id_2">
              {{ getFullName(item.driver_id_2) }}
            </div>
          </v-col>
          <v-col
            col="12"
            sm="6"
            lg="3"
          >
            Площадка: {{ getSiteById(item.site)?.name }}
          </v-col>
          <v-col
            col="12"
            sm="6"
            lg="3"
          > Тариф: {{ item.rate }} </v-col>
          <v-col
            col="12"
            sm="6"
            lg="3"
          >
            Дата создания заявки
            {{ moment(item.created_at).format("DD.MM.YYYY HH:mm") }}
          </v-col>
          <v-col
            col="12"
            sm="6"
            lg="3"
          > Маршрут: {{ item.route }} </v-col>
          <v-col
            col="12"
            sm="6"
            lg="3"
          >
            Дата обновления:
            {{ moment(item.updated_at).format("DD.MM.YYYY HH:mm") }}
          </v-col>
          <v-col
            col="12"
            sm="6"
            lg="3"
          >
            Количество дней проката: {{ item.count_day }}
          </v-col>
          <v-col
            col="12"
            sm="6"
            lg="3"
          >
            Итого: {{ item.price_day * item.count_day }}
          </v-col>
        </v-row>
        <v-row class="m-4">
          <v-col>
            <div class="more-info">Коментарий:</div>
            {{ item.comment }}
          </v-col>
        </v-row>
      </td>
    </template>
  </v-data-table>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { isEmpty } from "lodash";
import VueSlickCarousel from "vue-slick-carousel";
import { formatFio } from "../../formats/fio";
import moment from "../../../../plugins/moment";

export default {
  name: "RentHistory",
  components: { VueSlickCarousel },
  data: () => ({
    width: window.innerWidth,
    loading: false,
    search: "",
    sort: ["Новые", "Старые"],
    selected: [],
    expanded: [],
    singleExpand: false,
    slickOptions: {
      arrows: true,
      autoplay: false,
      dots: false,
      infinite: false,
      slidesToShow: 1,
    },
  }),
  methods: {
    moment,
    ...mapActions("rent", ["loadRents"]),
    ...mapActions("car", ["loadCars"]),
    ...mapActions("config", ["loadCity"]),
    notEmpty(item) {
      return !isEmpty(item);
    },
    updateWindowWidth() {
      this.width = window.innerWidth;
      if (!this.isMobile) {
        this.$emit("input", true);
      }
    },
    getFullName(person) {
      return formatFio(person) || "Фамилия Имя Отчество";
    },
  },
  computed: {
    ...mapGetters("rent", {
      rentsList: "getRentHistory",
    }),
    ...mapGetters("car", ["getCarByGuid"]),
    ...mapGetters("config", ["getSiteById"]),
    headers() {
      if (this.width < 992) {
        return [
          {
            text: "ID заявки",
            align: "start",
            sortable: true,
            value: "application_id",
          },
          { text: "Автомобиль", align: "start", value: "car_brand" },
          { text: "Дата аренды", align: "start", value: "start" },
          { text: "", value: "data-table-expand" },
        ];
      } else {
        return [
          {
            text: "ID заявки",
            align: "start",
            sortable: true,
            value: "application_id",
          },
          { text: "Автомобиль", align: "start", value: "car_brand" },
          {
            text: "Водитель",
            align: "start",
            value: "driver_id_1",
          },
          { text: "Статус", align: "start", value: "status" },
          { text: "Дата аренды", align: "start", value: "start" },
          { text: "", value: "data-table-expand" },
        ];
      }
    },
  },
  beforeMount() {
    this.loading = true;
  },
  async mounted() {
    window.addEventListener("resize", this.updateWindowWidth);
    await this.loadCars();
    await this.loadRents();
    await this.loadCity();
    this.loading = false;
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.updateWindowWidth);
  },
};
</script>

<style lang="scss">
.more-info {
  color: #717579;
}
.photo-slider {
  border-radius: 10px;
  display: block;
  overflow: hidden;
  width: 200px;

  &__item {
    display: block !important;
    width: 200px !important;

    a {
      display: block;
      height: 100%;
      width: 100%;

      img {
        display: block;
        height: 100%;
        object-fit: cover;
        object-position: center;
        width: 100%;
      }
    }
  }
  .slick-arrow {
    z-index: 3;
  }
  .slick-slide {
    display: block;
    height: 100px;
    width: 200px;
  }
  .slick-prev {
    left: 10px;
  }
  .slick-next {
    right: 10px;
  }
}
.data-table {
  &.theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper),
  .v-data-table__expanded.v-data-table__expanded__row {
    background: rgba($color: #ff6600, $alpha: 0.1);
  }
  .v-data-table__expand-icon {
    color: #ff6600;
  }
}
</style>
