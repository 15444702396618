<script>
import RentHistory from "@/modules/client/components/widgets/RentHistory.vue";

export default {
  name: "ClientRentHistory",
  components: { RentHistory },
};
</script>

<template>
  <div class="m-3">
    <div class="row g-3">
      <div class="col-12">
        <v-card>
          <rent-history />
        </v-card>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss"></style>
